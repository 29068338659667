import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import DisabilityDetails from '../components/DisabilityDetails';

const DisabilityTraining = () => {
  const [disability, setDisability] = useState();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const {reference} = useParams();

  useEffect(() => {
    const init = async () => {
      const response = await fetch(`/api/training/disabilities/${reference}`);
      if (response.ok) {
        setDisability(await response.json());
      } else {
        alert('Error fetching disability');
      }
    };
    init();
  }, [reference]);

  return (
    disability && (
      <Box display="flex" flexDirection={isMd ? 'row' : 'column'} sx={{mb: isMd ? '88px' : '72px'}}>
        <Box display="flex" justifyContent={!isMd ? 'center' : 'flex-start'}>
          {disability.image_url && (
            <img style={{height: 200, width: 200}} alt="" src={disability.image_url} />
          )}
        </Box>
        <Box sx={{ml: isMd ? 2 : 0, mt: isMd ? 0 : 2}}>
          <Typography variant="h1" color="primary" fontWeight={600}>
            {disability.name}
          </Typography>
          <DisabilityDetails disability={disability} />
        </Box>
      </Box>
    )
  );
};

export default DisabilityTraining;
