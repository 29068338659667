import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import '../InitSentry';
import TrainingWebApp from './TrainingWebApp';
import cmsTheme from '../CMS/CMSWebAppTheme';

const container = document.getElementById('training-web-app');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={cmsTheme}>
    <CssBaseline />
    <BrowserRouter>
      <TrainingWebApp />
    </BrowserRouter>
  </ThemeProvider>,
);
