import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Typography from '@mui/material/Typography';
import {spaceBottom} from '../useSharedStyles';

const DisabilityDetails = ({disability}) => {
  return (
    <div style={{marginTop: 20}}>
      <Typography variant="subtitle1" sx={{...spaceBottom, fontSize: 18}}>
        {disability.overview}
      </Typography>
      <Typography variant="h4">Tips</Typography>
      <List sx={spaceBottom}>
        {disability.tips.map((tip) => (
          <ListItem key={tip.description}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText>{tip.description}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography variant="h4">Resources</Typography>
      <List>
        {disability.resources.map((resource) => (
          <ListItem key={resource.link_text}>
            <ListItemIcon>
              {resource.type === 'video' ? <OndemandVideoIcon /> : <LinkIcon />}
            </ListItemIcon>
            <ListItemText>
              <a href={resource.url} title={resource.url} target="__blank">
                {resource.link_text}
              </a>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DisabilityDetails;
