import {Route} from 'react-router-dom';
import Container from '@mui/material/Container';

import SentryRoutes from '../SentryRoutes';
import TrainingHome from './/TrainingHome';
import DisabilityTraining from './DisabilityTraining';
import TrainingWebAppFooter from './TrainingWebAppFooter';

const TrainingWebApp = () => {
  return (
    <>
      <Container maxWidth="lg" sx={{pt: 2}}>
        <SentryRoutes>
          <Route path="/" element={<TrainingHome />} />
          <Route path="/disabilities/:reference" element={<DisabilityTraining />} />
        </SentryRoutes>
      </Container>
      <TrainingWebAppFooter />
    </>
  );
};

export default TrainingWebApp;
