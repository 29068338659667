import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PolicyIcon from '@mui/icons-material/Policy';
import ArticleIcon from '@mui/icons-material/Article';
import LoginIcon from '@mui/icons-material/Login';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import Container from '@mui/material/Container';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import {getBaseUrlForSubdomain} from '../util';

import WelcomeLogo from '../images/welcome-logo.png';

const TrainingWebAppFooter = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const appUrl = getBaseUrlForSubdomain('app');
  return (
    <Paper elevation={4} square sx={{position: 'fixed', bottom: 0, width: '100%'}}>
      <Container
        maxWidth="lg"
        sx={{display: 'flex', alignItems: 'center', px: isMd ? 4 : 0, py: isMd ? 2 : 1}}>
        {isMd && <img style={{width: '120px'}} src={WelcomeLogo} alt="Homepage" />}
        <BottomNavigation sx={{flex: 1}} showLabels>
          {isMd ? (
            <>
              <BottomNavigationAction
                label="Privacy policy"
                showLabel
                icon={<PolicyIcon fontSize="large" />}
                component={Link}
                href="https://www.wel-co.me/business-privacy-policy"
              />
              <BottomNavigationAction
                label="T&Cs"
                showLabel
                icon={<ArticleIcon fontSize="large" />}
                component={Link}
                href="https://www.wel-co.me/service-agreement"
              />
            </>
          ) : null}
          <BottomNavigationAction
            label="Login"
            icon={<LoginIcon fontSize="large" />}
            component={Link}
            href={appUrl}
          />
          <BottomNavigationAction
            label="Support"
            icon={<ContactSupportIcon fontSize="large" />}
            component={Link}
            href="https://www.wel-co.me/resources"
          />
          <BottomNavigationAction
            label="Get in touch"
            icon={<ContactPageIcon fontSize="large" />}
            component={Link}
            href="https://www.wel-co.me/getintouch"
          />
        </BottomNavigation>
      </Container>
    </Paper>
  );
};
export default TrainingWebAppFooter;
